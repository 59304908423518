import {
  IconBolt,
  IconChevronDown,
  IconEdit,
  IconMenu2,
  IconTarget,
  IconX,
} from "@tabler/icons"
import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import LogoWhiteSVG from "../images/logo-white.svg"
import LogoSVG from "../images/logo.svg"

import * as NavigationMenu from "@radix-ui/react-navigation-menu"

const menuItemClass =
  "hover:border-lilac hover:bg-lilac/10 p-3 rounded-lg transition cursor-pointer block border border-transparent flex gap-2"

const MenuItem = ({ to, heading, subhead, icon, isNew }) => (
  <Link to={to} className={menuItemClass}>
    {icon}
    <div>
      <div className="flex gap-2 items-center text-light-grey text-lg">
        <span>{heading}</span>{" "}
        {isNew && <div className="text-sm text-secondary-green">New</div>}
      </div>
      <span className="text-dark-grey block text-xs font-sans">{subhead}</span>
    </div>
  </Link>
)

const SubmenuDemoButton = () => (
  <Link to="/gocardless">
    <div
      className="rounded-lg gradient-background hover:bg-lilac transition w-full
      h-full p-12 text-white cursor-pointer"
    >
      <div className="text-2xl my-12">40% more revenue efficiency.</div>
      <div>Find out how &rarr;</div>
    </div>
  </Link>
)

const Header = ({ variant }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const menuItemClass = "text-dark-grey hover:text-white transition"

  return (
    <nav className="transition hover:bg-slate-700 z-40">
      {isMenuOpen && (
        <div className="w-full bg-black h-screen top-0 left-0 p-6">
          <div className="flex justify-between items-center">
            <div className="w-36">
              <img src={LogoWhiteSVG} />
            </div>
            <IconX
              size={36}
              className="text-white cursor-pointer"
              onClick={() => setMenuOpen(false)}
            />
          </div>
          <div className="flex flex-col gap-1.5 text-4xl text-dark-grey mt-12">
            <Link to="/" className={menuItemClass}>
              Home
            </Link>
            <Link to="/why-tactic" className={menuItemClass}>
              Why Tactic?
            </Link>
            <Link to="/product" className={menuItemClass}>
              Product
            </Link>
            <div className="text-base text-slate-100 pt-4">Use cases</div>
            <Link to="/sales" className={menuItemClass}>
              Sales
            </Link>
            <Link to="/marketing" className={menuItemClass}>
              Marketing
            </Link>
            <Link to="/revenue-operations" className={menuItemClass}>
              RevOps &amp; SalesOps
            </Link>
            <div className="h-4"></div>
            <Link to="/gocardless" className={menuItemClass}>
              Customer Stories
            </Link>
            <Link to="/data-points" className={menuItemClass}>
              Data Marketplace
            </Link>
            <Link to="/blog" className={menuItemClass}>
              Blog
            </Link>
            <Link
              to="https://apply.workable.com/tactic/"
              className={menuItemClass}
            >
              Jobs
            </Link>
            <Link
              to="https://app.tactic.fyi/"
              className="hover:text-white mt-12"
              rel="nofollow"
            >
              Sign in
            </Link>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 sm:px-6">
        <div className="flex py-4 lg:py-0 justify-between items-center lg:justify-start md:space-x-10">
          <div className="flex justify-between lg:w-0 lg:flex-1">
            <Link to="/" className="w-32 lg:w-36">
              {variant == "white" ? (
                <img src={LogoWhiteSVG} />
              ) : (
                <img src={LogoSVG} />
              )}
            </Link>
          </div>
          <div className="block lg:hidden">
            {!isMenuOpen && (
              <IconMenu2
                size={36}
                className="text-white cursor-pointer"
                onClick={() => setMenuOpen(true)}
              />
            )}
          </div>

          <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0 text-base gap-4 h-full">
            <NavigationMenu.Root>
              <NavigationMenu.List className="flex text-white text-base gap-4">
                <NavigationMenu.Item>
                  <NavigationMenu.Trigger className="data-[state=open]:text-blue transition flex items-center py-8">
                    Platform
                    <IconChevronDown size={16} />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="bg-slate-800 shadow-2xl w-full absolute left-0 z-40">
                    <div className="container mx-auto py-4 flex flex-col lg:flex-row">
                      <div className="p-3 flex-1">
                        <div className="font-sans text-lilac ml-3 text-xs">
                          Products
                        </div>
                        <MenuItem
                          to="/product"
                          heading="Tactic for Revenue"
                          subhead="Extract accurate, relevant customer data from your data, the web, GPT"
                          icon={
                            <IconTarget className="mt-1 text-soft-tones-green" />
                          }
                        />
                        <MenuItem
                          to="/generative-insights"
                          heading="Generate"
                          subhead="Generate knowledge 10x faster from any document, anywhere"
                          icon={
                            <IconBolt className="mt-1 text-soft-tones-purple" />
                          }
                          isNew
                        />
                      </div>
                      <div className="p-3 flex-1">
                        <div className="font-sans text-lilac ml-3 text-xs">
                          Enterprise
                        </div>
                        <MenuItem
                          to="/integrations"
                          heading="Integrations"
                          subhead="20+ enterprise tools"
                        />
                        <MenuItem
                          to="/customer-success"
                          heading="Customer Success"
                          subhead="Close data gaps"
                        />
                        {/* <MenuItem
                          to="/why-tactic"
                          heading="Security and Compliance"
                          subhead="SOC II and GDPR"
                        />
                        <MenuItem
                          to="/why-tactic"
                          heading="Languages and regions"
                          subhead="20+ languages and regions"
                        /> */}
                      </div>
                      <div className="flex-1 p-3">
                        <SubmenuDemoButton />
                      </div>
                    </div>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavigationMenu.Trigger className="data-[state=open]:text-blue transition flex items-center py-8">
                    Solutions
                    <IconChevronDown size={16} />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="bg-slate-800 shadow-2xl w-full absolute left-0 z-40">
                    <div className="container mx-auto py-4 flex flex-col lg:flex-row">
                      <div className="p-3 flex-1">
                        <div className="font-sans text-lilac ml-3 text-xs">
                          Teams
                        </div>
                        <MenuItem
                          to="/solutions#strategy"
                          heading="Strategy"
                          subhead="Find and optimise target markets."
                        />

                        <MenuItem
                          to="/marketing"
                          heading="Marketing"
                          subhead="ABM and demand gen with hyper targeted audiences."
                        />

                        <MenuItem
                          to="/sales"
                          heading="Sales"
                          subhead="Automate data and research. Generate more pipeline."
                        />

                        <MenuItem
                          to="/solutions#customer-success"
                          heading="Customer Success"
                          subhead="2x more upsell and renewal opportunities."
                        />

                        <MenuItem
                          to="/solutions#partnerships"
                          heading="Partnerships"
                          subhead="Grow your partnership ecosystem with data."
                        />

                        <MenuItem
                          to="/revenue-operations"
                          heading="Operations and Enablement"
                          subhead="Shorten your support queue for data."
                        />
                      </div>
                      <div className="p-3 flex-1">
                        <div className="font-sans text-lilac ml-3 text-xs">
                          Use Cases
                        </div>

                        <StaticQuery
                          query={graphql`
                            query {
                              allContentfulUseCase {
                                nodes {
                                  title
                                  slug
                                }
                              }
                            }
                          `}
                          render={data => {
                            return data.allContentfulUseCase.nodes.map(node => (
                              <MenuItem
                                to={"/use-case/" + node.slug}
                                heading={node.title}
                                subhead=""
                              />
                            ))
                          }}
                        />

                        <div className="font-sans text-lilac ml-3 text-xs border-t border-white/10 pt-4">
                          Customer Success
                        </div>
                        <MenuItem
                          to="/customer-success"
                          heading="Customer Success & Professional Services"
                          subhead="Our world class success team helps you get started with a bang."
                        />
                      </div>

                      <div className="p-3 flex-1">
                        <SubmenuDemoButton />
                      </div>
                    </div>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                  <NavigationMenu.Trigger className="data-[state=open]:text-blue transition flex items-center py-8">
                    Customers
                    <IconChevronDown size={16} />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="bg-slate-800 shadow-2xl w-full absolute left-0 z-40">
                    <div className="container mx-auto py-4 flex flex-col lg:flex-row">
                      <div className="p-3 flex-1">
                        <div className="font-sans text-lilac ml-3 text-xs">
                          Customer Spotlights
                        </div>
                        <MenuItem
                          to="/gocardless"
                          heading="Customer Stories"
                          subhead="Learn how Tactic is a staple for teams."
                        />

                        <MenuItem
                          to="/referrals"
                          heading="Customer Referral Program"
                          subhead="£150 for every successful customer referral."
                        />
                      </div>
                      <div className="p-3 flex-1">
                        <div className="font-sans text-lilac ml-3 text-xs">
                          Resources
                        </div>
                        <MenuItem
                          to="/blog"
                          heading="Blog"
                          subhead="The latest news and resources for automating B2B go-to-market"
                        />
                        <MenuItem
                          to="/blog/what-is-a-go-to-market-strategy/"
                          heading="Go-to-market 101"
                          subhead="Everything you need to go-to-market and grow revenue"
                        />

                        <MenuItem
                          to="https://help.quantcopy.com"
                          heading="Help Center"
                          subhead="Everything you need to get started"
                        />
                      </div>
                      <div className="p-3 flex-1"></div>
                    </div>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item className="hover:text-blue transition px-1 rounded-lg whitespace-nowrap py-8">
                  <Link to="/data-points">Data Marketplace</Link>
                </NavigationMenu.Item>

                <NavigationMenu.Item className="hover:text-blue transition px-1 rounded-lg whitespace-nowrap py-8">
                  <Link to="https://app.tactic.fyi/" rel="nofollow">
                    Sign in
                  </Link>
                </NavigationMenu.Item>
              </NavigationMenu.List>

              <NavigationMenu.Viewport />
            </NavigationMenu.Root>

            <Link
              to="/win-with-tactic/"
              className="
              bg-white py-3 px-4 shadow-xl text-black hover:text-blue text-lg 
              rounded-lg leading-none cursor-pointer transition whitespace-nowrap
              border border-transparent hover:border-blue"
            >
              Request Demo
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  data: PropTypes.any,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
