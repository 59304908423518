import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import Footer from "./footer"
import Header from "./header"

import DataPointListingBG from "../images/data-points/data-points-bg-min.png"
import Gradient from "../images/Gradient.svg"
import UseCaseBackground from "../images/use-case-background.svg"

import { IconX } from "@tabler/icons"
import { Slide, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Helmet } from "react-helmet"

const Layout = ({ variant, showNav = true, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  var backgroundCSS = `url(${Gradient}) no-repeat 50% -500px #111`

  if (variant == "case-study") {
    backgroundCSS = `url(${Gradient}) no-repeat 50% -1100px #111`
  }

  if (variant == "useCase") {
    backgroundCSS = `url(${UseCaseBackground}) no-repeat 50% -1000px #111`
  }

  if (variant == "dataPointListing") {
    backgroundCSS = `url(${DataPointListingBG}) no-repeat -1200px -1450px/3500px #111`
  }

  if (variant == "dataPoint") {
    backgroundCSS = `url(${DataPointListingBG}) no-repeat -300px -1200px/3000px #222`
  }

  if (variant == "plain") {
    backgroundCSS = "#111"
  }

  if (variant == "darkerGrey") {
    backgroundCSS = "#495763"
  }

  return (
    <div style={{ background: backgroundCSS }}>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-B8TD5K9DQK"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-B8TD5K9DQK');`}
        </script>
      </Helmet>
      {showNav && (
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          variant="white"
        />
      )}
      <main>{children}</main>
      <Footer></Footer>
      <ToastContainer
        position="top-center"
        hideProgressBar
        closeOnClick
        transition={Slide}
        closeButton={
          <div>
            <IconX className="text-dark-grey hover:text-blue" />
          </div>
        }
      />
    </div>
  )
}

export default Layout
