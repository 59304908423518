import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import {
  IconBrandGithub,
  IconBrandTwitter,
  IconBrandLinkedin,
  IconArrowUpRight,
} from "@tabler/icons"
import LogoSVG from "../images/logo-white.svg"

const Footer = () => (
  <footer>
    <div className="container mx-auto px-4 sm:px-6">
      <div className="grid sm:grid-cols-9 gap-8 py-8 md:py-12">
        <div className="sm:col-span-12 lg:col-span-3">
          <div className="mb-2">
            <Link className="inline-block -ml-2 -mt-2" to="/">
              <img src={LogoSVG} width="340px" />
            </Link>
          </div>
          <div className="text-sm text-medium-grey flex flex-col gap-2">
            <Link
              className="text-medium-grey hover:text-indigo-500 transition"
              to="/terms-and-conditions.html"
              target="blank"
            >
              Terms and Conditions
              <IconArrowUpRight className="inline text-gray-400" size={16} />
            </Link>
            <Link
              className="text-medium-grey hover:text-indigo-500 transition"
              to="https://help.quantcopy.com/privacy-policy"
              target="blank"
            >
              Privacy Policy
              <IconArrowUpRight className="inline text-gray-400" size={16} />
            </Link>
            <Link
              className="text-medium-grey hover:text-indigo-500 transition"
              to="/data-processing-addendum.html"
              target="blank"
            >
              Data Processing Addendum
              <IconArrowUpRight className="inline text-gray-400" size={16} />
            </Link>
          </div>
        </div>
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 className="text-lilac font-medium mb-2">Product</h6>
          <ul className="text-sm">
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/why-tactic"
              >
                Why Tactic?
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/#customers"
              >
                Customers
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/#use-cases"
              >
                Use Cases
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/#integrations"
              >
                Integrations
              </Link>
            </li>
          </ul>
          <h6 className="text-lilac font-medium mb-2 mt-6">Teams</h6>
          <ul className="text-sm">
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/sales"
              >
                Sales
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/marketing"
              >
                Marketing
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/revenue-operations"
              >
                Revenue and Sales Operations
              </Link>
            </li>
          </ul>
        </div>
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 className="text-lilac font-medium mb-2">Use Cases</h6>
          <ul className="text-sm">
            <StaticQuery
              query={graphql`
                query {
                  allContentfulUseCase {
                    nodes {
                      title
                      slug
                    }
                  }
                }
              `}
              render={data => {
                return data.allContentfulUseCase.nodes.map(node => (
                  <li className="mb-2">
                    <Link
                      className="text-medium-grey hover:text-indigo-500 transition"
                      to={"/use-case/" + node.slug}
                    >
                      {node.title}
                    </Link>
                  </li>
                ))
              }}
            />
          </ul>
          <h6 className="text-lilac font-medium mt-6 mb-2">Resources</h6>
          <ul className="text-sm">
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/blog/what-is-a-go-to-market-strategy/"
              >
                Go-to-market 101
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/blog/you-dont-need-personalisation/"
              >
                Personalisation vs. Relevance
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/blog/the-missing-50-of-your-customers-journey/"
              >
                Tactic vs. Intent Data
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/blog/account-research-training-template/"
              >
                Account research training template
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/use-case/digital-transformation/"
              >
                Companies doing digital transformation
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/use-case/cyber-security/"
              >
                Companies with cybersecurity initiatives
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/use-case/vision-mission-initiatives/"
              >
                Finding vision and mission of companies
              </Link>
            </li>
          </ul>
        </div>
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 className="text-lilac font-medium mb-2">Customers</h6>
          <ul className="text-sm">
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/referrals"
              >
                Customer Referral Program
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="https://help.quantcopy.com/"
                target="blank"
              >
                Help Center
              </Link>
            </li>
          </ul>
          <h6 className="text-lilac font-medium mb-2 mt-6">Company</h6>
          <ul className="text-sm">
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li className="mb-2">
              <Link
                className="text-medium-grey hover:text-indigo-500 transition"
                to="https://apply.workable.com/tactic/"
              >
                Jobs{" "}
                <span className="bg-red-400 -mt-1 text-white px-1 rounded-xl text-xs">
                  5
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="md:flex md:items-center md:justify-between py-4 md:py-8">
        <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
          <li>
            <Link
              className="flex justify-center items-center text-medium-grey hover:text-indigo-500"
              aria-label="Twitter"
              to="https://twitter.com/tactic_fyi"
            >
              <IconBrandTwitter />
            </Link>
          </li>
          <li className="ml-4">
            <Link
              className="flex justify-center items-center text-medium-grey hover:text-indigo-500"
              aria-label="Github"
              to="https://github.com/quantcopy/"
            >
              <IconBrandGithub />
            </Link>
          </li>
          <li className="ml-4">
            <Link
              className="flex justify-center items-center text-medium-grey hover:text-indigo-500"
              aria-label="Linkedin"
              to="https://www.linkedin.com/company/tactic-fyi"
            >
              <IconBrandLinkedin />
            </Link>
          </li>
        </ul>
        <div className="text-sm text-medium-grey mr-4">
          © Quantcopy Growth AI Ltd trading as Tactic 2019 &mdash;{" "}
          {new Date().getFullYear()}, powered by ☕🍝🏋️‍♂️ from code to close
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
